import { EMPTY_ARRAY } from 'common/constants';
import useAppNaviagte from 'common/hooks/useAppNaviagate';
import useMultiPageForm from 'common/hooks/useMultiPageForm';
import { useSaveResource } from 'common/remoteResource/resourceCUD';
import Throbber from 'common/ui/Throbber';
import { uploadFile } from 'common/utils/general';

import useWorkoutList from '../DAL/useWorkoutList';
import { WorkoutBuildFormData, WorkoutDetailsFormData, WorkoutFormData } from '../types';

import FormHeader from './FormHeader';
import { deepCloneExercise, mapWorkoutFormDataToPayload } from './mapper';
import WorkoutBuildForm from './WorkoutBuildForm';
import WorkoutDetailsForm from './WorkoutDetailsForm';

const blankFormData: WorkoutFormData = {
  details: {
    name: '',
    is_draft: false,
    cover_image: '',
    category: '',
    difficulty_level: 'N/A',
    type: 'quick',
    description: '',
  },
  build: {
    name: '',
    workout_duration: '60',
    exercises: EMPTY_ARRAY,
  },
};

interface WorkoutFormProps {
  defaultWorkoutFormData?: WorkoutFormData;
  workoutId?: string;
}

function WorkoutForm(props: WorkoutFormProps) {
  const { defaultWorkoutFormData = blankFormData, workoutId } = props;
  const { navigate } = useAppNaviagte();
  const { currentSection, formData, setCurrentSection, setFormData } =
    useMultiPageForm<WorkoutFormData>(defaultWorkoutFormData, 'details');
  const { loadWorkouts } = useWorkoutList({ disconnected: true });

  const {
    isSaveIP,
    isSaveError,
    saveMessage,
    setSaveInProgress,
    setSaveError,
    setSaveSuccess,
    saveResource,
    resetSuccessCount,
    getSuccessCount,
  } = useSaveResource({ url: '/api/admin/workout' });

  const gotoBuildSection = (detailsFormData: WorkoutDetailsFormData) => {
    setFormData('details', detailsFormData);
    setFormData('build', { name: detailsFormData.name });
    setCurrentSection('build');
  };

  const gotoDetailsSection = (buildFormdata: WorkoutBuildFormData) => {
    setFormData('build', buildFormdata);
    setFormData('details', { name: buildFormdata.name });
    setCurrentSection('details');
  };

  const handleSaveWorkout = async (workoutFormData: WorkoutFormData, isDraft: boolean = false) => {
    setSaveInProgress();
    const workoutPayload = mapWorkoutFormDataToPayload(workoutFormData);
    workoutPayload.workout.cover_image = await uploadFile(workoutFormData.details.cover_image);
    workoutPayload.workout.is_draft = isDraft;
    const result = await saveResource({ payload: workoutPayload, resourceId: workoutId });
    if (result) {
      setSaveSuccess();
      loadWorkouts({ page: 1 });
      navigate('/library/workouts');
    } else {
      setSaveError('Unable to save workout.');
    }
  };

  const onSaveDraftFromDetails = (detailsFromData: WorkoutDetailsFormData) => {
    setFormData('details', detailsFromData);
    const workoutFormData = {
      details: detailsFromData,
      build: formData.build,
    };
    return handleSaveWorkout(workoutFormData, true);
  };
  const onSaveDraftFromBuild = (buildFormdata: WorkoutBuildFormData) => {
    setFormData('build', buildFormdata);
    const workoutFormData = {
      details: formData.details,
      build: buildFormdata,
    };
    return handleSaveWorkout(workoutFormData, true);
  };
  function moveUp(exercise_id: string, buildFormdata: WorkoutBuildFormData) {
    let localBuildExercises = [...buildFormdata.exercises];
    const itemsToMove = localBuildExercises.filter((x) => x.exercise_id === exercise_id);
    for (const item of itemsToMove) {
      let index = localBuildExercises.findIndex((e) => e.unique_id === item.unique_id);
      if (index > 0 && isSameType(item, localBuildExercises[index - 1])) {
        let el = formData.build.exercises[index];
        localBuildExercises[index] = localBuildExercises[index - 1];
        localBuildExercises[index - 1] = el;
      }
    }

    setFormData('build', { ...buildFormdata, exercises: localBuildExercises });

    setCurrentSection('details');
    setTimeout(() => {
      setCurrentSection('build');
    }, 1);
  }
  function isSameType(exerciseA: any, exerciseB: any): boolean {
    return (
      exerciseA.workout_duration === exerciseB.workout_duration &&
      exerciseA.exercise_type === exerciseB.exercise_type
    );
  }
  function moveDown(exercise_id: string, buildFormdata: WorkoutBuildFormData) {
    let localBuildExercises = [...buildFormdata.exercises];
    const itemsToMove = localBuildExercises.filter((x) => x.exercise_id === exercise_id);
    for (const item of itemsToMove) {
      let index = localBuildExercises.findIndex((e) => e.unique_id === item.unique_id);
      if (
        index !== -1 &&
        index < localBuildExercises.length - 1 &&
        isSameType(item, localBuildExercises[index + 1])
      ) {
        let el = formData.build.exercises[index];
        localBuildExercises[index] = localBuildExercises[index + 1];
        localBuildExercises[index + 1] = el;
      }
    }
    setFormData('build', { ...buildFormdata, exercises: localBuildExercises });
    setCurrentSection('details');
    setTimeout(() => {
      setCurrentSection('build');
    }, 1);
  }
  const onPublishWorkout = async (buildFormdata: WorkoutBuildFormData) => {
    setFormData('build', buildFormdata);
    const workoutFormData = {
      details: formData.details,
      build: buildFormdata,
    };
    return handleSaveWorkout(workoutFormData);
  };
  const onSwapTo15 = async () => {
    const previous15 = formData.build.exercises.filter((x) => x.workout_duration === 15);

    if (previous15.length === 0) {
      const toCopy = formData.build.exercises
        .filter((x) => x.workout_duration === 60)
        .map((x) => ({
          ...deepCloneExercise(x),
          unique_id: `${x.unique_id.split('::')[0]}::${15}min`,
          workout_duration: 15,
        }));
      const updatedExercise = [...formData.build.exercises];
      toCopy.forEach((exe) => {
        const index = updatedExercise.findIndex((e) => e.unique_id === exe.unique_id);
        if (index === -1) {
          updatedExercise.push(exe);
        } else {
          updatedExercise[index] = exe;
        }
      });
      setFormData('build', { ...formData.build, exercises: updatedExercise });
    }
  };

  return (
    <div className="overflow-hidden h-appContent relative">
      <div className="contentArea">
        <FormHeader
          currentSection={currentSection}
          isError={isSaveError}
          message={saveMessage}
          workoutSavedCount={getSuccessCount()}
          resetSaveCount={resetSuccessCount}
        />
        {currentSection === 'details' ? (
          <WorkoutDetailsForm
            onSaveDraft={onSaveDraftFromDetails}
            goNextSection={gotoBuildSection}
            defaultValues={formData.details}
            onSwapTo15={onSwapTo15}
          />
        ) : (
          <WorkoutBuildForm
            moveUp={moveUp}
            moveDown={moveDown}
            workoutType={formData.details.type}
            goPreviousSection={gotoDetailsSection}
            onPublishWorkout={onPublishWorkout}
            onSaveDraft={onSaveDraftFromBuild}
            defaultValues={formData.build}
          />
        )}
        {isSaveIP ? <Throbber withMask /> : null}
      </div>
    </div>
  );
}

export default WorkoutForm;
